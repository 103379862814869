import React from 'react';

const Spinner = ({ isVisible }) => {
	if (!isVisible) return null;

	return (
		<div className="fixed inset-0 bg-gray-400 bg-opacity-25 flex justify-center items-center">
			<div className="spinner border-t-4 border-b-4 border-blue-500 rounded-full w-16 h-16 animate-spin"></div>
		</div>
	);
};

export default Spinner;