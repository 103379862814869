import React, { useState } from 'react';
import './ToggleButton.css';

function ToggleButton({setOption}) {
    const [alignment, setAlignment] = useState('monthly');

    const handleToggle = (value) => {
        setAlignment(value);
        setOption(value);
    };

    return (
        <div className="toggle-button-group">
            <button
                type="button"
                className={`toggle-button ${alignment === 'monthly' ? 'active' : ''}`}
                onClick={() => handleToggle('monthly')}
            >
                Monthly - $2 USD
            </button>
            <button
                type="button"
                className={`toggle-button ${alignment === 'yearly' ? 'active' : ''}`}
                onClick={() => handleToggle('yearly')}
            >
                Yearly - $16 USD
            </button>
        </div>
    );
}

export default ToggleButton;
