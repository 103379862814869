import useMediaQuery from "../hooks/useMediaQuery"
import { motion } from "framer-motion"
import extIcon from "../assets/extensionIcon.svg"
import { useState } from "react"
import SignUpDialog from "../shared/SignUpDialog"

const Instructions = () => {
  const isAboveMd = useMediaQuery("(min-width: 768px)")
  const isLandscapeMobile = useMediaQuery(
    "(max-height: 440px) and (orientation: landscape)"
  )
  const [signingUp, setSigningUp] = useState(false)
  return (
    <section
      id="instructions"
      className="flex flex-col items-center h-screen py-16"
    >
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.4, duration: 0.6 }}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
      >
        {/* HEADER================================================================================================ */}
        <h1 className="mx-auto text-xl md:text-3xl font-montserrat text-center text-gray-200 font-semibold p-4 pb-8 ss:pb-4 sm:pb-8 py-8 xs:pt-6 md:py-16">
          Get Started with SERVOMANCER
        </h1>
        {isAboveMd && !isLandscapeMobile ? (
          ""
        ) : (
          <div className="w-1/3 sm:w-60 mx-auto h-[1px] bg-gradient-brand  mb-8 ss:mb-4 sm:mb-8 landscape-mobile:hidden"></div>
        )}
      </motion.div>

      {/* DESKTOP VIEW (TILES)================================================================================================ */}
      {isAboveMd ? (
        <div className="xl:w-5/6 h-full flex flex-col items-center justify-center gap-16 landscape-mobile:gap-3 font-quest text-gray-100  pb-16">
          <div className="flex justify-between gap-2 md:gap-4 lg:gap-8 w-full text-base md:text-lg landscape-mobile:gap-2">
            {/* 1-------------------- */}
            <div className=" p-[1px] rounded-lg h-full w-1/4 the-one-static bg-gradient-brand">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="text-center h-full rounded-lg flex flex-col bg-neutral-950 pb-8 landscape-mobile:pb-2"
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1 },
                }}
              >
                <p className="text-2xl lg:text-3xl py-4 landscape-mobile:py-2 text-gray-400 ">
                  1
                </p>{" "}
                <div
                  onClick={() => setSigningUp(true)}
                  className="m-auto text-gray-200 border-[1px] border-neutral-800 hover:bg-neutral-800 bg-neutral-900 hover:text-gray-100 rounded-md py-4 w-[90%] hover:cursor-pointer"
                  // onClick={() => setSigningUp(true)}
                >
                  Sign Up Here!
                </div>
              </motion.div>
            </div>
            {/* 2----------------------- */}
            <div className=" p-[1px] rounded-lg h-full w-1/4 the-one-static bg-gradient-brand">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="text-center h-full rounded-lg flex flex-col bg-neutral-950 pb-8 landscape-mobile:pb-2"
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1 },
                }}
              >
                <p className="text-2xl lg:text-3xl py-4 landscape-mobile:py-2 text-gray-400">
                  2
                </p>

                <a
                  href={process.env.REACT_APP_CHROME_EXTENSION_LISTING_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="m-auto w-[90%] py-4 rounded-md hover:cursor-pointer text-gray-200 border-[1px] border-neutral-800 hover:bg-neutral-800 bg-neutral-900 hover:text-gray-100"
                >
                  download the SERVOMANCER extension here
                </a>
              </motion.div>
            </div>
            {/* 3--------------------------- */}
            <div className=" p-[1px] rounded-lg h-full w-1/4 the-one-static bg-gradient-brand">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="text-center h-full rounded-lg flex flex-col bg-neutral-950"
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1 },
                }}
              >
                <p className="text-2xl lg:text-3xl py-4 landscape-mobile:py-2 text-gray-400 ">
                  3
                </p>
                <div className="m-auto mb-8 px-4">
                  <p className="text-gray-200">
                    click the extension button
                    <span>
                      <img src={extIcon} alt="" className="h-16 mx-auto" />
                    </span>{" "}
                    <span className="landscape-mobile:hidden">
                      on your browser to login
                    </span>
                  </p>
                  <p className="landscape-mobile:hidden hidden md:inline">
                    (top right if using chrome)
                  </p>
                </div>
              </motion.div>
            </div>
            {/* 4------------------------------ */}
            <div className=" p-[1px] rounded-lg h-full w-1/4 the-one-static bg-gradient-brand">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="text-center h-full rounded-lg flex flex-col bg-neutral-950 pb-8 landscape-mobile:pb-2"
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1 },
                }}
              >
                <p className="text-2xl lg:text-3xl py-4 landscape-mobile:py-2 text-gray-400 ">
                  4
                </p>
                <div className="p-2 m-auto">
                  <p className="text-gray-200">Sign in to</p>
                  <a
                    href="https://www.tennistv.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    ATP TENNIS TV{" "}
                  </a>{" "}
                  <p className="text-gray-200">or</p>
                  <a
                    href="https://www.tennischannel.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    TENNIS CHANNEL PLUS
                  </a>{" "}
                  <p className="text-gray-200 landscape-mobile:hidden">
                    and start watching!
                  </p>
                </div>
              </motion.div>
            </div>{" "}
          </div>{" "}
          <p className="text-sm">
            SERVOMANCER is currently available on desktop only
          </p>
        </div>
      ) : (
        // MOBILE VIEW (NO TILES)========================================================================
        <div className="flex flex-col h-full items-center justify-center gap-20 landscape-mobile:gap-8 se-mobile:gap-4 pt-4 se-mobile:pt-0 se:pt-0">
          <motion.div
            initial="hidden"
            whileInView="visible"
            className="text-gray-200 font-quest flex flex-col gap-8 landscape-mobile:gap-4 text-base md:text-lg se-mobile:text-sm"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.7, duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: 50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <p className="">
              The SERVOMANCER extension is currently available to desktop
              browsers only
            </p>
            <p>
              However, you can still sign up or login below if you are using a
              mobile device
            </p>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            className="flex flex-col landscape-mobile:flex-row gap-4"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.7, duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <button
              onClick={() => setSigningUp(true)}
              className="z-10 w-40 the-one bg-gradient-to-r from-blue-500 to-teal-400 p-[1px] rounded-lg font-montserrat font-semibold text-gray-100 active:translate-y-[1px] active:translate-x-[1px]"
            >
              <div className="bg-neutral-950 hover:text-gray1 rounded-lg hover:bg-gradient-brand py-3 px-6 hover:text-neutral-950">
                Sign Up
              </div>
            </button>{" "}
            <div className="text-center hover:cursor-pointer shadow-1 z-10 w-40 border-[1.5px] border-gray-300 p-3 rounded-lg font-montserrat font-semibold text-gray-300 hover:bg-gradient-brand-gray hover:text-neutral-950 active:translate-y-[1px] active:translate-x-[1px]">
              <a
                href={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                My Account
              </a>
            </div>{" "}
          </motion.div>
        </div>
      )}
      <SignUpDialog isOpen={signingUp} setIsOpen={setSigningUp} />
    </section>
  )
}

export default Instructions
