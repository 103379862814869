import AnchorLink from "react-anchor-link-smooth-scroll"

const LinkDesktop = ({ page, selectedPage, setSelectedPage }) => {
  const lowerCasePage = page.toLowerCase()
  return (
    <AnchorLink
      className={`${
        selectedPage === lowerCasePage
          ? "navlink-desktop text-gray-100 z-40 bg-neutral-950"
          : "text-gray-200 hover:text-gray-100 hover:bg-neutral-700"
      } rounded-t-md pb-6 pt-3 mt-2 px-4 border-x-[1px] border-transparent -mb-[1px]`}
      href={`#${lowerCasePage}`}
      onClick={() => setSelectedPage(lowerCasePage)}
    >
      {page}
    </AnchorLink>
  )
}

export default LinkDesktop
