import AnchorLink from "react-anchor-link-smooth-scroll"

const Footer = ({ setSelectedPage }) => {
  return (
    <footer className="text-gray-200 pt-10">
      {" "}
      {/* Added flex, items-center, and justify-center to vertically center content */}
      <div className="flex flex-col h-full items-center justify-center text-center gap-4 w-full bg-neutral-900">
        {" "}
        {/* Ensured full width and centered alignment */}
        <AnchorLink
          onClick={() => setSelectedPage("home")}
          href="#home"
          className="z-10 w-full bg-neutral-800 hover:bg-neutral-700 font-montserrat py-2 sm:py-3 text-gray-400 hover:text-gray-300"
        >
          back to top
        </AnchorLink>
        <div className="pb-6">
          <h1 className="text-2xl sm:text-3xl font-montserrat text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-teal-400  py-2">
            SERVOMANCER
          </h1>
          <p className="font-quest text-sm sm:text-base">
            ©2024 SERVOMANCER. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
