import {motion} from "framer-motion"
import useMediaQuery from "../hooks/useMediaQuery"
import {useState} from "react";
import FailureDialog from "../shared/FailureDialog";
import SuccessDialog from "../shared/SuccessDialog";

const Contact = () => {
  const isAboveSm = useMediaQuery("(min-width: 768px)")
  const isLandscapeMobile = useMediaQuery(
    "(max-height: 440px) and (orientation: landscape)"
  )
	const isAboveLarge = useMediaQuery("(min-width: 768px)")
	const [contactEmail, setContactEmail] = useState('');
	const [contactName, setContactName] = useState('');
	const [contactMessage, setContactMessage] = useState('');
	const [showSuccessDialog, setShowSuccessDialog] = useState(false);
	const [showFailureDialog, setShowFailureDialog] = useState(false);

	const onSubmit = (event) => {
		event.preventDefault();
		var formdata = new FormData();
		formdata.append("email", contactEmail);
		formdata.append("full_name", contactName);
		formdata.append("message", contactMessage);
    
	  var requestOptions = {
				method: 'POST',
				body: formdata,
				redirect: 'follow'
	  };

	  fetch(`${process.env.REACT_APP_API_V2_URL}/contact`, requestOptions)
	    .then(response => setShowSuccessDialog(true))
	    .catch(error => setShowFailureDialog(true));
	}

	const handleContactEmailChange = e => {
		setContactEmail(e.target.value);
	}

	const handleContactNameChange = e => {
		setContactName(e.target.value);
	}

	const handleContactMessageChange = e => {
		setContactMessage(e.target.value);
	}

  return (
    <section
      id="contact"
      className="flex flex-col items-center justify-center landscape-mobile:justify-start min-h-[100vh] py-16  ss:pt-20 landscape-mobile:pt-16 short-screen:pt-24 sm:pt-16 landscape-mobile:py-4"
    >
	    <SuccessDialog open={showSuccessDialog} setOpen={setShowSuccessDialog} primary="We've received your message. Thank you so much for your feedback!"/>
	    <FailureDialog open={showFailureDialog} setOpen={setShowFailureDialog} primary="We were unable to receive your message. Please try again in a few minutes."/>
      <div className="flex flex-col md:flex-row w-full h-full gap-4 landscape-mobile:gap-0 sm:gap-8">
        {/* HEADER =========================================================================*/}
        <div className="my-auto  md:w-1/2">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.4, duration: 0.6 }}
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
          >
            <h1 className="mx-auto text-center text-xl md:text-3xl font-montserrat text-gray-100 font-semibold pb-8 se-mobile:pb-2 landscape-mobile:pb-4 ss:pb-4 sm:pb-8  sm:text-left landscape-mobile:text-left xs:pt-6">
              Contact Us
            </h1>
            {isAboveSm ? (
              ""
            ) : (
              <div className="w-1/4 sm:w-40 mx-auto h-[1px] bg-gradient-brand  mb-8 ss:mb-4 sm:mb-8 landscape-mobile:hidden"></div>
            )}
            {isAboveSm && (
              <div>
                <h3 className="w-3/4  text-gray-100 font-quest sm:text-base md:text-lg  landscape-mobile:hidden">
                  Feel free to contact us with business inquiries, feedback, or
                  any issues with the extension. We look forward to hearing from
                  you!
                </h3>
              </div>
            )}
            {/* ------------------------------------------- */}
          </motion.div>
        </div>

	      {/* FORMS */}
	      <div className="font-quest relative">
		      <motion.div
			      initial="hidden"
			      whileInView="visible"
			      viewport={{once: true, amount: 0.5}}
			      transition={{delay: 0.4, duration: 0.4}}
			      variants={{
				      hidden: {opacity: 0, y: 50},
				      visible: {opacity: 1, y: 0},
			      }}
			      className="basis-1/2 mt-10 md:mt-0"
		      >
				  <form onSubmit={onSubmit}>
					  <input
						  className="rounded-md text-gray-100 text-lg w-full p-3 bg-neutral-900 border-neutral-600 border-[1.5px] hover:border-neutral-100 mb-5"
						  type="text"
						  placeholder="NAME"
						  name="name"
						  required
						  maxLength="100"
						  value={contactName}
						  onChange={handleContactNameChange}
					  />
					  <input
						  className="rounded-md text-gray-100 text-lg w-full p-3 bg-neutral-900 border-neutral-600 border-[1.5px] hover:border-neutral-100 mb-5"
						  type="email"
						  placeholder="EMAIL"
						  name="email"
						  required
						  pattern="^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$"
						  value={contactEmail}
						  onChange={handleContactEmailChange}
					  />
					  <textarea
						  className="rounded-md text-gray-100 text-lg w-full p-3 bg-neutral-900 focus:bg-neutral-800 border-neutral-600 border-[1.5px] hover:border-neutral-100 mb-3"
						  name="message"
						  placeholder="MESSAGE"
						  rows="4"
						  required
						  maxLength="2000"
						  value={contactMessage}
						  onChange={handleContactMessageChange}
					  />
					  <button
						  type="submit"
						  className="shadow-1 z-10 w-full sm:w-auto border-[1.5px] border-gray-300 sm:p-8 p-4 rounded-lg font-montserrat font-semibold text-gray-300 hover:bg-gradient-brand-gray hover:text-neutral-950 active:translate-y-[1px] active:translate-x-[1px]"
					  >
						  Send Us a Message!
					  </button>
				  </form>
			  </motion.div>
		  </div>
	  </div>
	</section>
  )

}

export default Contact
