import React from "react"
import useMediaQuery from "../hooks/useMediaQuery"
import { motion } from "framer-motion"

const Demo = () => {
  const isAboveMd = useMediaQuery("(min-width: 1000px)")

  return (
    <section id="demo" className="flex flex-col items-center h-[100svh] py-16">
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.4, duration: 0.6 }}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
      >
        {/* HEADER================================================================ */}
        <h1 className="mx-auto text-xl md:text-3xl  font-montserrat text-center text-gray-100 font-semibold p-4 py-6 landscape-mobile:py-2 xs:pt-6 md:py-16">
          What does SERVOMANCER do?
        </h1>
      </motion.div>

      {/*CONTENT: DESKTOP VIEW================================================================ */}
      {isAboveMd ? (
        <div className="w-5/6 h-full flex items-center justify-center gap-16 font-quest text-gray-100 text-base md:text-lg">
          {/* TEXT SECTION------------------- */}

          <div className="w-2/5 flex">
            {" "}
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, x: 50 },
                visible: { opacity: 1, x: 0 },
              }}
            >
              <div className="flex flex-col gap-10 ">
                <h3 className="font-semibold text-base md:text-lg">
                  The 1hr 45min{" "}
                  <a
                    href="https://www.youtube.com/watch?v=D2I3RakqjsQ"
                    className="text-blue-500 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    match
                  </a>{" "}
                  to the right has been condensed to less than 1/2 hour by
                  Servomancer
                </h3>{" "}
                <h3 className="">
                  The Servomancer browser extension watches the match while you
                  do and skips over non-playing time
                </h3>
                <h3 className="">
                  NOTE: You must have a paid membership with{" "}
                  <a
                    href="https://www.tennistv.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    Tennis TV{" "}
                  </a>{" "}
                  or{" "}
                  <a
                    href="https://www.tennischannel.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    Tennis Channel Plus
                  </a>{" "}
                  to use Servomancer
                </h3>
              </div>
            </motion.div>
            {/* <ArrowRightIcon className="w-40 text-slate-700" /> */}
          </div>

          {/* VIDEO SECTION------------------- */}
          <div className="the-one-static w-3/5 p-[1px] bg-gradient-brand rounded-lg">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1 },
              }}
            >
              <video
                className="rounded-lg"
                width="100%"
                height="100%"
                controls
                controlsList="nodownload"
              >
                <source
                  src={process.env.REACT_APP_DEMO_VIDEO_KEY}
                  type="video/mp4"
                />
                Your browser does not support the video tag
              </video>
            </motion.div>
          </div>
        </div>
      ) : (
        // CONTENT: MOBILE VIEW================================================================
        <div className="w-full h-full flex flex-col sm:gap-16 items-center gap-16 xs:gap-4 landscape-mobile:gap-4 font-quest text-gray-200 text-base md:text-lg pt-6 landscape-mobile:pt-0 se-mobile:text-sm">
          {" "}
          <h3 className="text-sm hidden landscape-mobile:inline">
            The{" "}
            <a
              href="https://www.youtube.com/watch?v=D2I3RakqjsQ"
              className="text-blue-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              match
            </a>{" "}
            below has been condensed to about a third of the time by Servomancer
          </h3>
          {/* VIDEO SECTION------------------- */}
          <div className="the-one-static py-[1px] landscape-mobile:p-[1px] ss:p-[1px] ss:rounded bg-gradient-brand w-full landscape-mobile:w-1/2 ss:w-3/4  landscape-mobile:rounded sm:w-full sm:px-0 sm:rounded-none">
            <motion.div
              className=""
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1 },
              }}
            >
              <video
                className="landscape-mobile:rounded ss:rounded sm:rounded-none"
                width="100%"
                height="100%"
                controls
                controlsList="nodownload"
              >
                <source
                  src={process.env.REACT_APP_DEMO_VIDEO_KEY}
                  type="video/mp4"
                />
                Your browser does not support the video tag
              </video>
            </motion.div>
          </div>
          {/* TEXT SECTION------------------- */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            className="flex flex-col gap-10 w-5/6 mx-auto landscape-mobile:hidden"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
          >
            <h3 className="">
              The{" "}
              <a
                href="https://www.youtube.com/watch?v=D2I3RakqjsQ"
                className="text-blue-500 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                match
              </a>{" "}
              above has been condensed to about a third of the time by
              Servomancer
            </h3>

            <h3 className="landscape-mobile:hidden ss:hidden sm:inline">
              The extension watches the match while you do and skips over
              non-playing time
            </h3>
          </motion.div>
        </div>
      )}
    </section>
  )
}

export default Demo
